import { DataService } from 'src/app/services/data.service';
import { Activity } from './../../../models/activity';
import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-activity-all',
    templateUrl: './activity-all.html'
})
export class ActivityAllComponent implements OnInit
{
    activities : Activity[] = []
    loaded = false
    
    constructor(
        private data : DataService
    ) {}
    
    ngOnInit()
    {
        this.data.loadActivitiesClientManager().then(() => {
            this.activities = this.data.activityData
            
            this.loaded = true
        })
    }
}