import { Model } from './model';

export class ProfileClientManager extends Model
{
    public static requiredFields = [
        'id',
        'name',
        'surname',
        'phoneNumber'
    ]
    public static optionalFields = [
        'note',
        'gender'
    ]
    
    public id : number
    public name : string
    public surname : string
    public phoneNumber : string
    public gender : string
    public note : string
    
    public static loadFromJSONObject(json) : ProfileClientManager
    {
        var profile = new ProfileClientManager()
        this.load(json, profile, this.requiredFields, this.optionalFields)
        
        return profile
    }
}