import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService
{
    public apiHost = 'https://api.morebike.it/api/'
    public ERR_GENERIC = -10
    public ERR_USER_NOT_FOUND = -100
    public ERR_UNAUTHORIZED = -200
    
    constructor() {}
}