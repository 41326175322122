import { AuthService } from './../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core'
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-manager-login',
	templateUrl: './manager-login.component.html',
	styleUrls: ['./manager-login.component.scss']
})
export class ManagerLoginComponent implements OnInit 
{
	loginForm : FormGroup
	submitted = false
    loading = false
    error = 0
	
	constructor(
		private formBuilder : FormBuilder,
        private auth : AuthService,
        public config : ConfigService,
        private router : Router
	) 
	{}
	
	get f() { return this.loginForm.controls }

	ngOnInit() 
	{
        if (this.auth.isAuthenticated())
        {
            this.router.navigate(['dashboard'])
        }
        
		this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        })
	}

	onSubmit()
	{
        this.submitted = true
        if (this.loginForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.auth.loginManager(
            this.f.username.value,
            this.f.password.value,
            (error, profile) => 
            {
                this.loading = false
                this.submitted = false
                this.error = error
                
                if (error)
                {
                    return
                }
                
                this.router.navigate(['dashboard'])
            }
        )
	}
}
