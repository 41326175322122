import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ClientManager } from 'src/app/models/client-manager';

@Component({
    selector: 'app-manager-home',
    templateUrl: './home.html'
})
export class ManagerHomeComponent implements OnInit
{
    userText = ""
    bikeText = ""
    lockerText = ""
    activityText = ""
    clients : ClientManager[] = []
    
    constructor(
        private api : ApiService,
        private data : DataService
    ) {}
    
    ngOnInit()
    {
        this.data.loadClientsAll().then(() => {
            this.userText = this.data.clientData.length.toString()
            this.clients = this.data.clientData.splice(this.data.clientData.length - 3, 3)
        })
        this.data.loadBikesManager().then(() => {
            this.bikeText = this.data.bikeData.length.toString()
        })
        this.data.loadLockersManager().then(() => {
            this.lockerText = this.data.lockerData.length.toString()
        })
        this.data.loadActivitiesClientManager().then(() => {
            this.activityText = this.data.activityData.length.toString()
        })
    }
}