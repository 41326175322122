import { AdminHomeComponent } from './pages/home/home';
import { PassTypeEditComponent } from './pages/pass-type-edit/pass-type-edit';
import { PassTypesComponent } from './pages/pass-types/pass-types';
import { AdminLockerCreate } from './pages/locker-create/locker-create';
import { AdminBikeUpdateComponent } from './pages/bikes-edit/bikes-edit';
import { ManagerEditComponent } from './pages/manager-edit/manager-edit';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AdminRoutingModule } from './admin-routing';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard';
import { ManagersAllComponent } from './pages/managers-all/managers-all';
import { ManagerRegisterComponent } from './pages/manager-register/manager-register';
import { UsersAllComponent } from './pages/users-all/users-all';
import { AdminBikeAllComponent } from './pages/bikes-all/bikes-all';
import { AdminLockerAllComponent } from './pages/lockers-all/lockers-all';
import { AdminBikeCreateComponent } from './pages/bikes-create/bikes-create';
import { AdminLockerEditComponent } from './pages/locker-edit/locker-edit';

@NgModule({
	declarations: [
		AdminDashboardComponent,
		ManagersAllComponent,
		ManagerRegisterComponent,
		ManagerEditComponent,
		UsersAllComponent,
		AdminBikeAllComponent,
		AdminLockerAllComponent,
		AdminBikeCreateComponent,
		AdminBikeUpdateComponent,
		AdminLockerCreate,
		AdminLockerEditComponent,
		PassTypesComponent,
		PassTypeEditComponent,
		AdminHomeComponent
	],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
	imports: [
		CommonModule,
		FormsModule,
		AdminRoutingModule,
		NgSelectModule,
		TranslateModule.forChild(),
		NgbModule,
		RouterModule,
		ReactiveFormsModule
	],
	providers: [
		TranslateService
	]
})
export class AdminModule {}