import { ConfigService } from './../../services/config.service';
import { ApiService } from './../../services/api.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from 'src/app/models/profile';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService 
{
    private userId : number = -1
    
    constructor(
        private api : ApiService,
        private config : ConfigService,
        private translate : TranslateService,
        private router : Router
	) 
	{
		
	}
	
	public isAuthenticated(): boolean
	{
        const token = localStorage.getItem('token')
        
        return token != undefined && token.length > 0
    }
    public getAaccessToken(): string
    {
        return localStorage.getItem('token')
    }
    public checkTokenExpired(err : HttpErrorResponse): Boolean
    {
        return this.api.checkTokenExpired(err)
    }
    
    public loginShared(username : string, password : string, callback : (error, isAdmin : boolean) => any)
    {
        this.api.loginShared(username, password).then(result => 
        {
            if (result['result'] == undefined)
            {
                return
            }
            
            if (result['result'] == 200)
            {
                var accessToken = result['data']['accessToken']
                
                localStorage.setItem('token', accessToken)
                localStorage.setItem('userid', result['data']['user']['id'])
                
                if (result['data']['user'].hasOwnProperty('profilemanager'))
                {
                    callback(false, false)
                    return
                }
                
                callback(false, true)
                return
            } else
            {
                throw this.translate.instant('unknown error')
            }
        }).catch(err => {
            let response = err as HttpErrorResponse
            if (response != undefined)
            {
                if (response.status == 404)
                {
                    callback(this.config.ERR_USER_NOT_FOUND, null)
                    return
                }
                switch(response.status)
                {
                    case 404:
                        callback(this.config.ERR_USER_NOT_FOUND, null)
                        break
                    case 401:
                        callback(this.config.ERR_UNAUTHORIZED, null)
                        break
                    default:
                        callback(this.config.ERR_GENERIC, null)
                }
                return
            }
            console.error(err)
            callback(this.config.ERR_GENERIC, null)
        })
    }
    
    public loginAdmin(username : string, password : string, callback : (error, profile : Profile) => any)
    {
        this.api.loginAdmin(username, password).then(result => 
        {
            try
            {   
                if (result['result'] == undefined)
                {
                    return
                }
                
                if (result['result'] == 200)
                {
                    var profile = Profile.loadFromJSONObject(result['data']['user'])
                    var accessToken = result['data']['accessToken']
                    
                    localStorage.setItem('token', accessToken)
                    localStorage.setItem('userid', profile.id.toString())
                    
                    callback(false, profile)
                    return
                } else
                {
                    throw this.translate.instant('unknown error')
                }
            } catch(error)
            {
                console.error(error)
                callback(error.toString(), null)
            }
        }).catch(err => {
            let response = err as HttpErrorResponse
            if (response != undefined)
            {
                if (response.status == 404)
                {
                    callback(this.config.ERR_USER_NOT_FOUND, null)
                    return
                }
                switch(response.status)
                {
                    case 404:
                        callback(this.config.ERR_USER_NOT_FOUND, null)
                        break
                    case 401:
                        callback(this.config.ERR_UNAUTHORIZED, null)
                        break
                    default:
                        callback(this.config.ERR_GENERIC, null)
                }
                return
            }
            console.error(err)
            callback(this.config.ERR_GENERIC, null)
        })
    }
    
    public loginManager(username : string, password : string, callback : (error, profile : Profile) => any)
    {
        this.api.loginManager(username, password).then(result => 
        {
            try
            {   
                if (result['result'] == undefined)
                {
                    return
                }
                
                if (result['result'] == 200)
                {
                    var profile = Profile.loadFromJSONObject(result['data']['user'])
                    var accessToken = result['data']['accessToken']
                    
                    localStorage.setItem('token', accessToken)
                    localStorage.setItem('userid', profile.id.toString())
                    
                    callback(false, profile)
                    return
                } else
                {
                    throw this.translate.instant('unknown error')
                }
            } catch(error)
            {
                console.error(error)
                callback(error.toString(), null)
            }
        }).catch(err => 
        {
            let response = err as HttpErrorResponse
            if (response != undefined)
            {
                if (response.status == 404)
                {
                    callback(this.config.ERR_USER_NOT_FOUND, null)
                    return
                }
                switch(response.status)
                {
                    case 404:
                        callback(this.config.ERR_USER_NOT_FOUND, null)
                        break
                    case 401:
                        callback(this.config.ERR_UNAUTHORIZED, null)
                        break
                    default:
                        callback(this.config.ERR_GENERIC, null)
                }
                return
            }
            console.error(err)
            callback(this.config.ERR_GENERIC, null)
        })
    }
    
    public loginClientManager(username : string, password : string, callback : (error) => any)
    {
        
    }
    
    public logout(redirect : any[])
    {
        localStorage.removeItem('token')
        localStorage.removeItem('userid')
        this.router.navigate(redirect)
    }
    
    public getProfileID(): number
    {
        var id = Number.parseInt(localStorage.getItem('userid'))
        
        if (Number.isNaN(id) || localStorage.getItem('userid') == undefined || localStorage.getItem('userid').length == 0)
        {
            throw 'Userid is not set'
        }
        
        return id
    }
}
