import { AdminLoginComponent } from './pages/admin-login/admin-login';
import { ClientLoginComponent } from './pages/client-login/client-login';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagerLoginComponent } from './pages/manager-login/manager-login.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { LoginComponent } from './pages/login/login';

const routes: Routes = [
    {
        path: 'login',
        component: AuthPageComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            },
            {
                path: 'admin/login',
                component: AdminLoginComponent
            },
            {
                path: 'manager/login',
                component: ManagerLoginComponent
            },
            {
                path: 'client/login',
                component: ClientLoginComponent
            }
        ]
    }
];

@NgModule({
	imports: [
        RouterModule.forChild(routes)
    ],
	exports: [RouterModule]
})
export class AuthRoutingModule { }
