import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Pass } from 'src/app/models/pass';
import { PassType } from 'src/app/models/pass-type';
import { ClientManager } from 'src/app/models/client-manager';

@Component({
    selector: 'app-pass-assign',
    templateUrl: './pass-assign.html'
})
export class PassAssignComponent implements OnInit
{
    loaded = false
    loading = false
    submitted = false
    assignForm : FormGroup
    userId = -1
    assigned = false
    
    types : PassType[] = []
    client : ClientManager
    
    constructor(
        private formBuilder : FormBuilder,
        private api : ApiService,
        private route : ActivatedRoute
    ) {}
    
    get f() { return this.assignForm.controls }
    
    ngOnInit()
    {
        this.assignForm = this.formBuilder.group({
            type: ['', Validators.required],
            
        })
        
        this.route.params.subscribe(params => {
            this.userId = +params['id']
            
            this.api.listPassTypes().then(result => {
                this.types = PassType.loadFromJSONArray(result['data']['arrType'])
                this.types = this.types.filter((value) => {
                    return value.visible == 1
                })
                
                this.api.getClientManagerProfile(this.userId).then(result => {
                    this.client = ClientManager.loadFromJSONObject(result['user'])
                    this.loaded = true
                })
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.assignForm.invalid)
        {
            return
        }
        
        var typeIndex = this.f.type.value
        var type = this.types[typeIndex]
        var pass = new Pass()
        pass.cost = type.price
        pass.paymentMethod = 'assigned'
        pass.stringPaypal = localStorage.getItem('userid')
        pass.typeId = type.id
        
        this.api.assignPass(pass, this.userId).then(result => {
            console.log(result)
            
            if (result['result'] == 200)
            {
                this.assigned = true
            }
        }).catch(err => {
            
        })
    }
}