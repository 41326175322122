import { Model } from './model';
import { ProfileManager } from './profile-manager';

export class Manager extends Model
{
    public static requiredFields = [
        'id',
        'username'
    ]
    public static optionalFields = [
        
    ]
    
    public id : number
    public username : string
    public password : string
    public active : boolean
    public profile : ProfileManager
    
    constructor()
    {
        super()
        this.profile = new ProfileManager() 
    }
    
    public static loadFromJSONObject(json)
    {
        var manager = new Manager()
        
        this.load(json, manager, this.requiredFields, this.optionalFields)
        
        manager.active = json['active'] == 1
        if (json['profilemanager'] !== null)
        {
            manager.profile = ProfileManager.loadFromJSONObject(json['profilemanager'])
        }
        
        return manager
    }
    
    public static loadFromJSONArray(json : any[]): Manager[]
    {
        var managers : Manager[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var manager = this.loadFromJSONObject(json[i])
            managers.push(manager)
        }
        
        return managers
    }
}