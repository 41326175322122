import { Bike } from './../../../models/bike';
import { DataService } from './../../../services/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { OnInit, Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-bike-all',
    templateUrl: './bike-all.component.html',
    styleUrls: ['./bike-all.component.scss']
})
export class BikeAllComponent implements OnInit
{
    public searchForm : FormGroup
    public altRow = false
    public bikes : Bike[] = []
    
    constructor(
        private api : ApiService,
        private config : ConfigService,
        public translate : TranslateService,
        private data : DataService,
        private formBuilder : FormBuilder,
        private router : Router,
        private modalService : NgbModal
    )
    {}
    
    ngOnInit()
    {
        this.searchForm = this.formBuilder.group({
            search: ['']
        })
        this.data.loadBikesManager().then(() => 
        {
            this.bikes = this.data.bikeData
        })
    }
    
    rowClick(i : number)
    {
        this.router.navigate(['/dashboard/bikes/edit/'+i.toString()])
    }
    
    deleteClick(i : number, modalContent)
    {
        if (i < 0 || i >= this.bikes.length)
        {
            return
        }
        this.modalService.open(modalContent).result.then(result => 
        {
            if (result === 'yes')
            {
                var bike = this.bikes[i]
                this.api.deleteBike(bike.id).then((result) => 
                {
                    if (result == undefined)
                    {
                        return
                    }
                    this.bikes.splice(i, 1)
                }).catch(err => 
                {
                    
                })
            }
        }, reject => {
            console.log(reject)
        })
    }
    
    searchSubmit()
    {
        
    }
}