import { ApiService } from './../../../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Bike } from 'src/app/models/bike';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-bike-update',
    templateUrl: './bikes-edit.html'
})
export class AdminBikeUpdateComponent implements OnInit
{
    public id : number
    public loaded = false
    public loading = false
    public submitted = false
    
    public bike : Bike
    public updateForm : FormGroup
    
    constructor(
        private route : ActivatedRoute,
        private data : DataService,
        private formBuilder : FormBuilder,
        public translate : TranslateService,
        private api : ApiService
    )
    {}
    
    ngOnInit()
    {
        this.loaded = false
        
        this.updateForm = this.formBuilder.group({
			brand: ['', Validators.required],
			model: ['', Validators.required],
			serialNumber: ['', Validators.required],
			qrCode: ['', Validators.required],
			public: []
		})
        
        this.route.params.subscribe(params => {
            this.id = +params['id']
            
            this.data.getBikeByID(this.id, (error, bike) => {
                if (error)
                {
                    return
                }
                this.onBikeLoaded(bike)
            })
        })
    }
    
    get f() { return this.updateForm.controls }
    
    onBikeLoaded(bike : Bike)
    {
        this.bike = bike
        this.loaded = true
        
        this.f.brand.setValue(bike.brand)
        this.f.model.setValue(bike.model)
        this.f.serialNumber.setValue(bike.serialNumber)
        this.f.public.setValue(bike.public)
        this.f.qrCode.setValue(bike.qrCode)
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.updateForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.bike.brand = this.f.brand.value
		this.bike.model = this.f.model.value
		this.bike.serialNumber = this.f.serialNumber.value
		this.bike.qrCode = this.f.qrCode.value
		this.bike.public = this.f.public.value
        
        this.api.updateBike(this.bike).then(result => {
            this.loading = false
            this.submitted = false
            
            if (result['result'] == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				console.log('Bike updated')
			} else
			{
				throw this.translate.instant('unknown error')
			}
        }).catch(err => {
            console.error(err)
            
            this.loading = false
            this.submitted = false
        })
    }
    
    disassociate()
    {
        this.api.disassociateBikeLocker(this.bike.bikeLockers[this.bike.bikeLockers.length-1].id).then(() => {
            this.bike.bikeLockers.pop()
        }).catch(err => {
            
        })
    }
}