import { Locker } from 'src/app/models/locker';
import { Manager } from './../models/manager';
import { Bike } from './../models/bike';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClientManager } from '../models/client-manager';
import { PassType } from '../models/pass-type';
import { identifierModuleUrl } from '@angular/compiler';
import { Pass } from '../models/pass';

@Injectable()
export class ApiService
{
	constructor(
		private http : HttpClient,
		private config : ConfigService,
		private router : Router
	)
	{
		
	}
	
	loginShared(username : string, password : string)
	{
		const url = this.config.apiHost + 'auth/signin'
		
		const json = {
			username,
			password
		}
		
		return this.postJson(url, json)
	}
	
	loginAdmin(username : string, password : string)
	{
		const url = this.config.apiHost + 'auth/signin'
		
		const json = {
			username,
			password
		}
		
		return this.postJson(url, json)
	}
	checkUserExists(username : string)
	{
		const url = this.config.apiHost + 'auth/checkUserExist'
		
		const json = {
			username
		}
		
		return this.postJsonAuth(url, json)
	}
	
	getManagersAll()
	{
		const url = this.config.apiHost + 'manager/admin/listAll'
		
		return this.getAuth(url)
	}
	getManagerByID(id : number)
	{
		const url = this.config.apiHost + 'manager/admin/profile/' + id.toString()
		
		return this.getAuth(url)
	}
	deleteManager(id : number)
	{
		const url = this.config.apiHost + 'manager/admin/deleteProfileManager/'+id.toString()
		
		return this.postJsonAuth(url, {})
	}
	deleteClient(id : number)
	{
		const url = this.config.apiHost + 'clientmanager/admin/deleteProfile/'+id.toString()
		
		return this.postJsonAuth(url, {})
	}
	
	deleteLocker(id: number)
	{
		const url = this.config.apiHost + 'locker/delete'
		
		return this.postJsonAuth(url, {id: id.toString()})
	}
	
	updateManagerProfile(manager : Manager)
	{
		const url = this.config.apiHost + 'manager/admin/userUpdate/'+manager.id.toString()
		
		const json = {
			name: manager.profile.name,
			surname: manager.profile.surname,
			phoneNumber: manager.profile.phoneNumber,
			ragioneSociale: manager.profile.ragioneSociale,
		}
		
		if (manager.profile.gender !== undefined)
		{
			json['gender'] = manager.profile.gender
		}
		
		return this.postJsonAuth(url, json)
	}
	listClientsAdmin(managerId : number)
	{
		const url = this.config.apiHost + 'clientmanager/admin/list/'+managerId.toString()
		
		return this.getAuth(url)
	}
	
	listPassTypes()
	{
		const url = this.config.apiHost + 'pass/type'
		
		return this.getAuth(url)
	}
	deletePassType(id : number)
	{
		const url = this.config.apiHost + 'pass/admin/type/delete'
		
		const json = {
			id
		}
		
		return this.postJsonAuth(url, json)
	}
	addPassType(pass : PassType)
	{
		const url = this.config.apiHost + 'pass/admin/type/add'
		
		const json = {
			desc: pass.desc,
			duration: pass.duration,
			price: pass.price
		}
		
		return this.postJsonAuth(url, json)
	}
	updatePassType(pass : PassType)
	{
		const url = this.config.apiHost + 'pass/admin/type/update'
		
		const json = {
			id: pass.id,
			desc: pass.desc,
			duration: pass.duration,
			price: pass.price,
			visible: pass.visible
		}
		
		return this.postJsonAuth(url, json)
	}
	getPassType(id : number)
	{
		const url = this.config.apiHost + 'pass/type/'+id.toString()
		
		return this.getAuth(url)
	}
	getClientManagerProfile(id : number)
	{
		const url = this.config.apiHost + 'clientmanager/admin/profile/'+id.toString()
		
		return this.getAuth(url)
	}
	updateClientManagerProfile(client : ClientManager)
	{
		const url = this.config.apiHost + 'clientmanager/admin/updateProfile'
		
		const json = {
			id: client.id,
			name: client.profile.name,
			surname: client.profile.surname,
			phoneNumber: client.profile.phoneNumber,
			note: client.profile.note,
		}
		if (client.profile.gender != undefined)
		{
			json['gender'] = client.profile.gender.toString()
		}
		
		return this.postJsonAuth(url, json)
	}
	deleteClientManager(id : number)
	{
		const url = this.config.apiHost + 'clientmanager/admin/deleteProfile/'+id.toString()
		
		return this.postJsonAuth(url, {})
	}
	
	assignPass(pass : Pass, idUser: number)
	{
		const url = this.config.apiHost + 'pass/admin/assignPass'
		
		const json = {
			idUser,
			idType: pass.typeId,
			cost: pass.cost,
			paymentMethod: 'assigned',
			stringPaypal: localStorage.getItem('userid')
		}
		
		return this.postJsonAuth(url, json)
	}
	
	loginManager(username : string, password : string)
	{
		const url = this.config.apiHost + 'auth/signin'
		
		const json = {
			username,
			password
		}
		
		return this.postJson(url, json)
	}
	
	createBike(bike : Bike)
	{
		const url = this.config.apiHost + 'bike/create'
		
		const json = {
			brand: bike.brand,
			model: bike.model,
			serialNumber: bike.serialNumber,
			qrCode: bike.qrCode,
			public: bike.public ? 1 : 0
		}
		
		return this.postJsonAuth(url, json)
	}
	updateBike(bike : Bike)
	{
		const url = this.config.apiHost + 'bike/update'
		
		const json = {
			id: bike.id,
			brand: bike.brand,
			model: bike.model,
			serialNumber: bike.serialNumber,
			qrCode: bike.qrCode,
			public: bike.public ? 1 : 0
		}
		
		return this.postJsonAuth(url, json)
	}
	
	getBikesAll()
	{
		const url = this.config.apiHost + 'bike/all'
		
		return this.getAuth(url)
	}
	getBikesManager()
	{
		const url = this.config.apiHost + 'bike/user/all'
		
		return this.getAuth(url)
	}
	getLockersManager()
	{
		const url = this.config.apiHost + 'locker/user/all'
		
		return this.getAuth(url)
	}
	getBikeByID(id : number)
	{
		const url = this.config.apiHost + 'bike/' + id.toString()
		
		return this.getAuth(url)
	}
	
	getActivityManagerUsers()
	{
		const url = this.config.apiHost + 'activity/manager/allUserRegistered'
		
		const json = {
			id: localStorage.getItem('userid')
		}
		
		return this.postJsonAuth(url, json)
	}
	getActivityAll()
	{
		const url = this.config.apiHost + 'activity/admin/all'
		
		return this.getAuth(url)
	}
	
	registerManager(manager : Manager)
	{
		const url = this.config.apiHost + 'manager/auth/signup'
		
		const json = {
			name: manager.profile.name,
			surname: manager.profile.surname,
			phoneNumber: manager.profile.phoneNumber,
			username: manager.username,
			password: manager.password,
			ragioneSociale: manager.profile.ragioneSociale,
			gender: manager.profile.gender
		}
		
		return this.postJsonAuth(url, json)
	}
	
	registerClientManager(client : ClientManager)
	{
		const url = this.config.apiHost + 'clientmanager/auth/signup'
		
		const json = {
			name: client.profile.name,
			surname: client.profile.surname,
			phoneNumber: client.profile.phoneNumber,
			username: client.username,
			password: client.password,
			idUserManager: client.idUserManager
		}
		
		return this.postJsonAuth(url, json)
	}
	listClientManagers()
	{
		const url = this.config.apiHost + 'clientmanager/list'
		
		return this.getAuth(url)
	}
	
	createLocker(udid : string, desc : string)
	{
		const url = this.config.apiHost + 'locker/create'
		
		const json = {
			udid,
			desc
		}
		
		return this.postJsonAuth(url, json)
	}
	
	getLockersAll()
	{
		const url = this.config.apiHost + 'locker/all'
		
		return this.getAuth(url)
	}
	
	getLockerByID(id : number)
	{
		const url = this.config.apiHost + 'locker/byId/'+id.toString()
		
		return this.getAuth(url)
	}
	updateLocker(locker : Locker)
	{
		const url = this.config.apiHost + 'locker/update'
		
		const json = {
			id: locker.id,
			desc: locker.desc
		}
		
		return this.postJsonAuth(url, json)
	}
	associateBikeLocker(bike : Bike, locker : number, lat : number, lon : number)
	{
		const url = this.config.apiHost + 'bikelocker/associate'
		
		const json = {
			idLocker: locker,
			idBike: bike.id,
			startLat: lat,
			startLng: lon
		}
		
		return this.postJsonAuth(url, json)
	}
	disassociateBikeLocker(id : number)
	{
		const url = this.config.apiHost + 'bikelocker/'+id.toString()
		
		return this.deleteAuth(url)
	}
	
	deleteBike(id : number)
	{
		const url = this.config.apiHost + 'bike/delete'
		
		const json = {
			id
		}
		
		return this.postJsonAuth(url, json)
	}
	
	private postJson(url, json, addHeaders : Array<{name:string, value:string}> = []): Promise<Object>
	{
		const data = JSON.stringify(json)
		
		let headers = new HttpHeaders({
			'Content-Type':  'application/json'
		})
		for (let i = 0; i < addHeaders.length; i++)
		{
			headers = headers.append(addHeaders[i].name, addHeaders[i].value)
		}
		
		const request = this.http.post(url, data, {
			headers,
		}).toPromise()
		
		/*request.subscribe(data => {
            
        }, error => {
            console.error(error)
        })*/
		request.catch(err => {
			console.error(err)
		})
		
		return request
	}
	private postJsonAuth(url, json)
	{
		const headers = [
			{
				name: 'x-access-token',
				value: localStorage.getItem('token')
			}
		]
		
		return this.postJson(url, json, headers).catch(err => 
		{
			this.checkTokenExpired(err)
		})
	}
	private get(url, addHeaders : Array<{name:string, value:string}> = []): Promise<Object>
	{
		let headers = new HttpHeaders({})
		for (let i = 0; i < addHeaders.length; i++)
		{
			headers = headers.append(addHeaders[i].name, addHeaders[i].value)
		}
		
		const request = this.http.get(url, {
			headers
		}).toPromise()
		
		request.catch(err => {
			console.error(err)
		})
		
		return request
	}
	private getAuth(url)
	{
		const headers = [
			{
				name: 'x-access-token',
				value: localStorage.getItem('token')
			}
		]
		
		return this.get(url, headers)
	}
	
	private deleteAuth(url)
	{
		let headers = new HttpHeaders({})
		headers = headers.append('x-access-token', localStorage.getItem('token'))
		
		const request = this.http.delete(url, {
			headers
		})
		
		return request.toPromise().catch(err => {
			console.error(err)
			
			this.checkTokenExpired(err)
		})
	}
	
	private deleteAuthJson(url, params)
	{
		let headers = new HttpHeaders({})
		headers = headers.append('x-access-token', localStorage.getItem('token'))
		headers = headers.append('Content-Type', 'applications/json')
		
		const request = this.http.request('delete', url, {
			headers,
			body: params
		})
		
		return request.toPromise().catch(err => {
			console.error(err)
			
			this.checkTokenExpired(err)
		})
	}
	
	public checkTokenExpired(err : HttpErrorResponse): Boolean
	{
		if (err == undefined)
		{
			return
		}
		try
		{
			if (err.status != 500)
			{
				return false
			}
			if ((err.error.msg as string).includes('Fail to Authentication') || (err.error.msg as string).includes('TokenExpiredError'))
			{
				console.log('Access Token expired')
				localStorage.setItem('token', '')
				this.router.navigate(['/manager/login'])
				return true
			}
		} catch(error)
		{
			console.error(error)
			return false
		}
	}
}