import { Locker } from './locker';
import { Model } from './model';

export class BikeLocker extends Model
{
    public static requiredFields = [
        'id',
        'idBike',
        'idLocker',
        'startLat',
        'startLng'
    ]
    public static optionalFields = []
    
    public id : number
    public idBike : number
    public idLocker : number
    public startLat : number
    public startLng : number
    public locker : Locker
    
    public static loadFromJSONObject(json): BikeLocker
    {
        var b = new BikeLocker()
        
        this.load(json, b, this.requiredFields, this.optionalFields)
        
        b.locker = Locker.loadFromJSONObject(json['locker'])
        
        return b
    }
    
    public static loadFromJSONArray(json : any[]) : BikeLocker[]
    {
        var b : BikeLocker[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var bike = this.loadFromJSONObject(json[i])
            b.push(bike)
        }
        
        return b
    }
}