import { ClientManager } from './../../../models/client-manager';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-client-edit',
    templateUrl: './client-edit.html'
})
export class ClientEditComponent implements OnInit
{
    updateForm : FormGroup
    submitted = false
    loaded = false
    loading = false
    id : number
    client : ClientManager
    
    constructor(
        private route : ActivatedRoute,
        private formBuilder : FormBuilder,
        private api : ApiService
    )
    {}
    
    get f() { return this.updateForm.controls }
    
    ngOnInit()
    {
        this.updateForm = this.formBuilder.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            phone: ['', Validators.required],
            note: [''],
            gender: ['']
        })
        
        this.route.params.subscribe(params => {
            this.id = +params['id']
            
            this.api.getClientManagerProfile(this.id).then(result => {
                console.log(result)
                
                this.client = ClientManager.loadFromJSONObject(result['user'])
                this.f.name.setValue(this.client.profile.name)
                this.f.surname.setValue(this.client.profile.surname)
                this.f.phone.setValue(this.client.profile.phoneNumber)
                this.f.note.setValue(this.client.profile.note)
                if (this.client.profile.gender != undefined)
                {
                    this.f.gender.setValue(this.client.profile.gender)
                }
                
                this.loaded = true
            }).catch(err => {
                
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = true
		if (this.updateForm.invalid)
		{
			return
		}
        this.loading = true
        
        this.client.profile.name = this.f.name.value
        this.client.profile.surname = this.f.surname.value
        this.client.profile.phoneNumber = this.f.phone.value
        this.client.profile.gender = this.f.gender.value.toString()
        this.client.profile.note = this.f.note.value
        
        this.api.updateClientManagerProfile(this.client).then(result => 
        {
            console.log(result)
            if (result == undefined)
            {
                return
            }
            
            if (result['result'] == undefined)
			{
				return
            }
            
            if (result['result'] == 200)
            {
                console.log('Client manager updated')
                
                this.loading = false
                this.submitted = false
            } else
            {
                throw 'Failed to register client: '+result
            }
        }).catch(err => 
        {
            console.error(err)
            this.loading = false
            this.submitted = false
        })
    }
}