import { Bike } from './../../../models/bike';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-bike-create',
	templateUrl: './bike-create.component.html',
	styleUrls: ['./bike-create.component.scss']
})
export class BikeCreateComponent implements OnInit 
{
	public createForm : FormGroup
	public submitted = false
	public loading = false
	
	constructor(
		public config : ConfigService,
		private api : ApiService,
		private formBuilder : FormBuilder,
		public translate : TranslateService,
		private router : Router
	) 
	{ }
	
	get f() { return this.createForm.controls }

	ngOnInit() 
	{
		this.createForm = this.formBuilder.group({
			brand: ['', Validators.required],
			model: ['', Validators.required],
			serialNumber: ['', Validators.required],
			qrCode: ['', Validators.required],
			public: []
		})
	}
	
	onSubmit()
	{
		this.submitted = true
		if (this.createForm.invalid)
		{
			return
		}
		this.loading = true
		
		var bike = new Bike()
		bike.brand = this.f.brand.value
		bike.model = this.f.model.value
		bike.serialNumber = this.f.serialNumber.value
		bike.qrCode = this.f.qrCode.value
		bike.public = this.f.public.value
		
		this.api.createBike(bike).then(result => 
		{
			if (result['result'] == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
                console.log('Bike created')
                console.log(result)
				this.router.navigate(['/dashboard/bikes/edit/'+result['data']['bike']['id']])
			} else
			{
				throw this.translate.instant('unknown error')
			}
		}).catch(err => 
		{
			console.error(err)
			this.loading = false
			this.submitted = false
		})
	}
}
