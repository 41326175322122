import { BikeLocker } from './bike-locker';
import { Locker } from 'src/app/models/locker';
import { Model } from './model';

export class Bike extends Model
{
    private static requiredFields : string[] = [
        'id',
        'brand',
        'model',
        'serialNumber',
        'qrCode'
    ]
    private static optionalFields : string[] = []
    
    public id : number
    public brand : string
    public model : string
    public serialNumber : string
    public qrCode : string
    public public : boolean
    public bikeLockers : BikeLocker[]
    
    public hasLocker(): boolean
    {
        return this.bikeLockers.length > 0
    }
    
    public static loadFromJSONObject(json): Bike
    {
        var bike = new Bike()
        this.load(json, bike, this.requiredFields, this.optionalFields)
        
        bike.public = json['public'] == 1
        bike.bikeLockers = BikeLocker.loadFromJSONArray(json['bikelockers'])
        
        return bike
    }
    
    public static loadFromJSONArray(json : []): Bike[]
    {
        var bikes : Bike[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            bikes.push(Bike.loadFromJSONObject(json[i]))
        }
        
        return bikes
    }
}