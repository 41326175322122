import { DataService } from './services/data.service';
import { AuthGuardService } from './auth/services/auth-guard.services';
import { ConfigService } from './services/config.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/services/auth.service';
import { ManagerModule } from './manager/manager.module';
import { AdminModule } from './admin/admin-module';

export function HttpLoaderFactory(http: HttpClient) 
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule,
        AuthModule,
        ManagerModule,
        AdminModule,
		AppRoutingModule
	],
	providers: [
        AuthService,
        AuthGuardService,
        ApiService,
        HttpClient,
        ConfigService,
        TranslateService,
        DataService
    ],
	bootstrap: [AppComponent]
})
export class AppModule { }
