import { ClientEditComponent } from './pages/client-edit/client-edit';
import { BikeAssociateComponent } from './pages/bike-associate/bike-associate';
import { LockerEditComponent } from './pages/locker-edit/locker-edit';
import { BikeUpdateComponent } from './pages/bike-update/bike-update.component';
import { BikeAllComponent } from './pages/bike-all/bike-all.component';
import { ManagerDashboardComponent } from './pages/manager-dashboard/manager-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/services/auth-guard.services';
import { BikeCreateComponent } from './pages/bike-create/bike-create.component';
import { ClientAllComponent } from './pages/client-all/client-all';
import { ClientCreateComponent } from './pages/client-create/client-create';
import { LockerAllComponent } from './pages/locker-all/locker-all';
import { PassAssignComponent } from './pages/pass-assign/pass-assign';
import { ManagerHomeComponent } from './pages/home/home';
import { LockerCreate } from './pages/locker-create/locker-create';
import { ActivityAllComponent } from './pages/activity-all/activity-all';

const routes : Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        component: ManagerDashboardComponent,
        children: [
            {
                path: 'home',
                component: ManagerHomeComponent
            },
            {
                path: 'bikes/all',
                canActivate: [AuthGuardService],
                component: BikeAllComponent
            },
            {
                path: 'bikes/create',
                canActivate: [AuthGuardService],
                component: BikeCreateComponent
            },
            {
                path: 'bikes/edit/:id',
                canActivate: [AuthGuardService],
                component: BikeUpdateComponent
            },
            {
                path: 'bikes/associate/:id',
                component: BikeAssociateComponent
            },
            {
                path: 'clients/all',
                canActivate: [AuthGuardService],
                component: ClientAllComponent
            },
            {
                path: 'clients/create',
                canActivate: [AuthGuardService],
                component: ClientCreateComponent
            },
            {
                path: 'clients/edit/:id',
                component: ClientEditComponent
            },
            {
                path: 'lockers/all',
                canActivate: [AuthGuardService],
                component: LockerAllComponent
            },
            {
                path: 'lockers/edit/:id',
                canActivate: [AuthGuardService],
                component: LockerEditComponent
            },
            {
                path: 'lockers/create',
                component: LockerCreate
            },
            {
                path: 'pass/assign/:id',
                component: PassAssignComponent
            },
            {
                path: 'activity/all',
                component: ActivityAllComponent
            }
        ]
    },
]

@NgModule({
    declarations: [
    ],
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class ManagerRoutingModule {}