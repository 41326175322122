import { AuthService } from './../../../auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-manager-dashboard',
    templateUrl: './manager-dashboard.component.html',
    styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent implements OnInit 
{
    public bikeCollapse = true
    public lockerCollapse = true
    public clientsCollapse = true
    
    constructor(
        public translate : TranslateService,
        private title : Title,
        private auth : AuthService
    ) 
    {
        title.setTitle(translate.instant('Dashboard'))
    }

    ngOnInit() 
    {
    }
    
    logoutClick()
    {
        this.auth.logout(['/login'])
    }
}
