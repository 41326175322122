import { ConfigService } from 'src/app/services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-client-login',
    templateUrl: './client-login.html'
})
export class ClientLoginComponent implements OnInit
{
    public loginForm : FormGroup
    public loading = false
    public submitted = false
    
    constructor(
        private formBuilder : FormBuilder,
        public config : ConfigService
    )
    {
        
    }
    
    ngOnInit()
    {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        })
    }
    
    onSubmit()
    {
        
    }
}