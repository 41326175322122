export class ModelFactory
{
    public create<T>(type : (new() => T)): T 
    {
        return new type()
    }
}

export class Model
{
    protected static load<T>(json, object : T, required : string[], optional : string[]): T
    {   
        for (var i = 0; i < required.length; i++)
        {
            let param = required[i]
            if (!json.hasOwnProperty(param))
            {
                throw 'Missing parameter: '+param
            }
            object[param] = json[param]
        }
        for (var i = 0; i < optional.length; i++)
        {
            let param = optional[i]
            if (json.hasOwnProperty(param))
            {
                object[param] = json[param]
            }
        }
        
        return object
    }
}