import { Model } from './model';

export class Locker extends Model
{
    public static requiredFields = [
        'id',
        'udid',
        'desc'
    ]
    public static optionalFields = []
    
    public id : number
    public udid : string
    public desc : string
    
    public static loadFromJSONObject(json): Locker
    {
        var locker = new Locker()
        
        this.load(json, locker, this.requiredFields, this.optionalFields)
        
        return locker
    }
    
    public static loadFromJSONArray(json : []): Locker[]
    {
        var lockers : Locker[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var locker = this.loadFromJSONObject(json[i])
            lockers.push(locker)
        }
        
        return lockers
    }
}