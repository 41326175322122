import { Model } from './model';

export class ProfileManager extends Model
{
    private static requiredFields = 
    [
        'id',
        'name',
        'surname',
        'phoneNumber',
        'ragioneSociale'
    ]
    private static optionalFields = [
        'gender',
        'imgUrl',
    ]
    
    public id : number
    public name : string
    public surname : string
    public gender : string
    public imgUrl : string
    public phoneNumber : string
    public ragioneSociale : string
    
    public static loadFromJSONObject(json): ProfileManager
    {
        var profile = new ProfileManager()
        this.load<ProfileManager>(json, profile, this.requiredFields, this.optionalFields)
        
        return profile
    }
}