import { Model } from './model';

export class Pass extends Model
{
    public static requiredFields = [
        
    ]
    public static optionalFields = [
        
    ]
    
    public id : number
    public typeId : number
    public cost : number
    public paymentMethod : string
    public stringPaypal : string
    
    public static loadFromJSONObject(json)
    {
        var type = new Pass()
        
        this.load(json, type, this.requiredFields, this.optionalFields)
        
        return type
    }
    
    public static loadFromJSONArray(json : [])
    {
        var types : Pass[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var type = this.loadFromJSONObject(json[i])
            types.push(type)
        }
        
        return types
    }
}