import { OnInit, Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Locker } from 'src/app/models/locker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lockers-all',
    templateUrl: './lockers-all.html'
})
export class AdminLockerAllComponent implements OnInit
{
    public lockers : Locker[] = []
    
    constructor(
        private data : DataService,
        private translate : TranslateService,
        private modalService : NgbModal,
        private api : ApiService,
        private router : Router
    )
    {
        
    }
    
    ngOnInit()
    {
        this.data.loadLockersAll().then(() => {
            this.lockers = this.data.lockerData
            this.lockers = this.lockers.sort(this.lockerSorter)
        })
    }
    
    private lockerSorter(a : Locker, b : Locker) 
	{
		if (a.udid == b.udid) {
			return 0;
		} else {
			return a.udid < b.udid ? -1 : 1;
		}
	}
    
    rowClick(i : number)
    {
        this.router.navigate(['/admin/dashboard/lockers/edit/'+i.toString()])
    }
    
    deleteClick(i : number, modalContent)
    {
        if (i < 0 || i >= this.lockers.length)
        {
            return
        }
        this.modalService.open(modalContent).result.then(result => 
        {
            if (result === 'yes')
            {
                var locker = this.lockers[i]
                this.api.deleteLocker(locker.id).then((result) => 
                {
                    if (result == undefined)
                    {
                        return
                    }
                    this.lockers.splice(i, 1)
                }).catch(err => 
                {
                    
                })
            }
        }, reject => {
            console.log(reject)
        })
    }
}