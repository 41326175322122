import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigService } from './../../../services/config.service';
import { DataService } from 'src/app/services/data.service';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PassType } from 'src/app/models/pass-type';
import { ClientManager } from 'src/app/models/client-manager';

@Component({
	selector: 'app-pass-all',
	templateUrl: './pass-types.html'
})
export class PassTypesComponent implements OnInit
{
	id = -1
	loaded = false
	public passes : PassType[] = []
	createForm : FormGroup
	submitted = false
	loading = false
	public disablingPass
	
	constructor(
		private config : ConfigService,
		private data : DataService,
		private route : ActivatedRoute,
		private api : ApiService,
		private modalService : NgbModal,
		private formBuilder : FormBuilder
	) {}
	
	get f() { return this.createForm.controls }
	
	ngOnInit()
	{
		this.createForm = this.formBuilder.group({
			desc: ['', Validators.required],
			duration: ['', Validators.required],
			price: ['', Validators.required]
		})
		
		this.route.params.subscribe(params => {
			this.id = +params['id']
			
			this.api.listPassTypes().then(result => {
				if (result['result'] == 200)
				{
					var passes = PassType.loadFromJSONArray(result['data']['arrType'])
					this.passes = passes
				}
			}).catch(err => {
				
			})
		})
	}
	
	createSubmit()
	{
		this.submitted = true
		if (this.createForm.invalid)
		{
			return
		}
		this.loading = true
		
		var type = new PassType()
		type.desc = this.f.desc.value
		type.duration = this.f.duration.value
		type.price = this.f.price.value
		
		this.api.addPassType(type).then(result => 
		{
			console.log(result)
			
			if (result == undefined || result['result'] !== 200)
			{
				return
			}
			
			type.id = result['data']['id']
			this.passes.push(type)
			
			this.f.desc.setValue('')
			this.f.duration.setValue(undefined)
			this.f.price.setValue(undefined)
			
			this.loading = false
			this.submitted = false
		}).catch(err => {
			this.loading = false
			this.submitted = false
		})
	}
	
	deleteClick(i : number, modalContent)
	{
		if (i < 0 || i >= this.passes.length)
		{
			return
		}
		this.disablingPass = this.passes[i].visible
		
		this.modalService.open(modalContent).result.then(result => 
		{
			if (result === 'yes')
			{
				this.passes[i].visible = this.passes[i].visible == 0 ? 1 : 0
				this.api.updatePassType(this.passes[i]).then(() => {
					
				}).catch(err => {
					
				})
				/*this.api.deletePassType(pass.id).then((result) => 
				{
					if (result == undefined)
					{
						return
					}
					this.passes.splice(i, 1)
				}).catch(err => 
				{
                    
				})*/
			}
		}, reject => {
			console.log(reject)
		})
	}
}