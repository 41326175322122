import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PassType } from 'src/app/models/pass-type';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-pass-type-edit',
    templateUrl: './pass-type-edit.html'
})
export class PassTypeEditComponent implements OnInit
{
    id = -1
    type : PassType
    loaded = false
    loading = false
    submitted = false
    updateForm : FormGroup
    
    constructor(
        private route : ActivatedRoute,
        private api : ApiService,
        private formBuilder : FormBuilder
    ) {}
    
    get f() { return this.updateForm.controls }
    
    ngOnInit()
    {
        this.updateForm = this.formBuilder.group({
            desc: ['', Validators.required],
            price: ['', Validators.required],
            duration: ['', Validators.required]
        })
        
        this.route.params.subscribe(params => {
            this.id = +params['id']
            
            this.api.getPassType(this.id).then(result => 
            {
                console.log(result)
                this.type = PassType.loadFromJSONObject(result['data'])
                
                this.f.desc.setValue(this.type.desc)
                this.f.duration.setValue(this.type.duration)
                this.f.price.setValue(this.type.price)
            }).catch(err => {
                
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.updateForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.type.desc = this.f.desc.value
        this.type.duration = this.f.duration.value
        this.type.price = this.f.price.value
        
        this.api.updatePassType(this.type).then(result => 
        {
            console.log(result)
            
            if (result == undefined || result['result'] !== 200)
            {
                return
            }
            
            this.loading = false
            this.submitted = false
        }).catch(err => {
            this.loading = false
            this.submitted = false
        })
    }
}