import { Manager } from 'src/app/models/manager';
import { Locker } from 'src/app/models/locker';
import { AuthService } from './../auth/services/auth.service';
import { Injectable } from '@angular/core';
import { Bike } from '../models/bike';
import { ApiService } from './api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientManager } from '../models/client-manager';
import { Activity } from '../models/activity';

@Injectable()
export class DataService 
{
    public bikeData : Bike[]
    public clientData : ClientManager[]
    public lockerData : Locker[]
    public activityData : Activity[]
    public managerData : Manager[]
	
	constructor(
		private api :ApiService,
		private auth : AuthService
	) { }
    
    public loadManagersAll()
    {
        return this.api.getManagersAll().catch(err => {
            console.error(err)
            
            this.auth.checkTokenExpired(err)
        })
    }
    
	public loadBikesAll()
	{
		return this.api.getBikesAll().then(result => 
		{
			if (result == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				var bikes = Bike.loadFromJSONArray(result['data'])
				this.bikeData = bikes
			} else
			{
				throw new Error('Could not load bike data, result: '+result)
			}
		}).catch(err => 
		{
			console.error(err)
			let http = err as HttpErrorResponse
			this.auth.checkTokenExpired(http)
		})
    }
    public loadBikesManager()
    {
        return this.api.getBikesManager().then(result => 
        {
            if (result == undefined)
            {
                return
            }
            
            if (result['result'] == 200)
            {
                var bikes = Bike.loadFromJSONArray(result['data'])
                this.bikeData = bikes
            } else
            {
                throw new Error('Could not load bike data, result: '+result)
            }
        }).catch(err => 
        {
            console.error(err)
            let http = err as HttpErrorResponse
            this.auth.checkTokenExpired(http)
        })
    }
    
    public getManagerById(id : number, callback : (error, manager : Manager) => any)
    {
        this.api.getManagerByID(id).then(result => 
        {
            if (result == undefined)
            {
                return
            }
            
            if (result['result'] == 200)
            {
                var manager = Manager.loadFromJSONObject(result['user'])
                callback(false, manager)
            } else
            {
                throw new Error('Could not load manager data: '+result)
            }
        }).catch(err => 
        {
            console.error(err)
			let http = err as HttpErrorResponse
			this.auth.checkTokenExpired(http)
        })
    }
    
	public getBikeByID(id : number, callback : (error, bike : Bike) => any)
	{
		this.api.getBikeByID(id).then(result =>
		{
			if (result['result'] == 200)
			{
				var bike = Bike.loadFromJSONObject(result['data'])
				callback(false, bike)
			} else
			{
				throw new Error('Could not load bike data: '+result)
			}
		}).catch(err => 
		{
			console.error(err)
			if (this.auth.checkTokenExpired(err))
			{
				return
			}
			callback(err.toString(), null)
		})
    }
    
    public loadActivitiesClientManager()
    {
        return this.api.getActivityManagerUsers().then(result => 
        {
            console.log(result)
            if (result == undefined || result['result'] != 200)
            {
                console.log('could not load activity data')
                return
            }
            
            this.activityData = Activity.loadFromJSONArray(result['data'])
        }).catch(err => 
        {
            console.error(err)
        })
    }
    public loadActivitiesAll()
    {
        return this.api.getActivityAll().then(result => 
        {
            console.log(result)
            
            if (result == undefined || result['result'] != 200)
            {
                console.log('could not load activity data')
                return
            }
            
            this.activityData = Activity.loadFromJSONArray(result['data'])
        }).catch(err => 
        {
            console.error(err)
        })
    }
    
    public loadClientsAll()
    {
        return this.api.listClientManagers().then(result => 
        {
            if (result['result'] == 200)
            {
                var clients = ClientManager.loadFromJSONArray(result['user'])
                this.clientData = clients
            }
        }).catch(err => 
        {
            console.error(err)
            let http = err as HttpErrorResponse
			this.auth.checkTokenExpired(http)
        })
    }
    
    public loadManagersListAll()
    {
        return this.api.getManagersAll().then(result => 
        {
            if (result == undefined)
            {
                return
            }
            
            if (result['result'] == 200)
            {
                this.managerData = Manager.loadFromJSONArray(result['user'])
            } else
            {
                throw new Error('Could not load manager data, result: '+result)
            }
        }).catch(err => 
        {
            console.error(err)
        })
    }
	
	public loadLockersAll()
	{
		return this.api.getLockersAll().then(result => 
		{
			if (result == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				this.lockerData = Locker.loadFromJSONArray(result['data'])
			} else
			{
				throw new Error('Could not load lockers data, result: '+result)
			}
		}).catch(err => {
			console.error(err)
			let http = err as HttpErrorResponse
			this.auth.checkTokenExpired(http)
		})
    }
    public loadLockersManager()
    {
        return this.api.getLockersManager().then(result => {
            if (result == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				this.lockerData = Locker.loadFromJSONArray(result['data'])
			} else
			{
				throw new Error('Could not load lockers data, result: '+result)
			}
        }).catch(err => {
            console.error(err)
			let http = err as HttpErrorResponse
			this.auth.checkTokenExpired(http)
        })
    }
    
	public getLockerByID(id : number, callback : (error, locker : Locker) => any)
	{
		this.api.getLockerByID(id).then(result => {
			if (result == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				if (result['data'] == null)
				{
					throw 'Locker null'
				}
				var locker = Locker.loadFromJSONObject(result['data'])
				callback(false, locker)
			} else
			{
				throw new Error('could not load locker by id: '+id)
			}
		}).catch(err => {
			console.error(err)
			if (this.auth.checkTokenExpired(err))
			{
				return
			}
			callback(err.toString(), null)
		})
	}
}
