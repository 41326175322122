import { AdminBikeUpdateComponent } from './pages/bikes-edit/bikes-edit';
import { AdminLockerAllComponent } from './pages/lockers-all/lockers-all';
import { AdminBikeAllComponent } from './pages/bikes-all/bikes-all';
import { ManagerEditComponent } from './pages/manager-edit/manager-edit';
import { ManagerRegisterComponent } from './pages/manager-register/manager-register';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagersAllComponent } from './pages/managers-all/managers-all';
import { UsersAllComponent } from './pages/users-all/users-all';
import { AdminBikeCreateComponent } from './pages/bikes-create/bikes-create';
import { AdminLockerCreate } from './pages/locker-create/locker-create';
import { AdminLockerEditComponent } from './pages/locker-edit/locker-edit';
import { PassTypesComponent } from './pages/pass-types/pass-types';
import { PassTypeEditComponent } from './pages/pass-type-edit/pass-type-edit';
import { AdminHomeComponent } from './pages/home/home';

var routes : Routes = [
    {
        path: 'admin/dashboard',
        component: AdminDashboardComponent,
        children: [
            {
                path: 'managers/all',
                component: ManagersAllComponent
            },
            {
                path: 'manager/register',
                component: ManagerRegisterComponent
            },
            {
                path: 'manager/edit/:id',
                component: ManagerEditComponent
            },
            {
                path: 'users/all/:id',
                component: UsersAllComponent
            },
            {
                path: 'bikes/all',
                component: AdminBikeAllComponent
            },
            {
                path: 'bikes/create',
                component: AdminBikeCreateComponent
            },
            {
                path: 'bikes/edit/:id',
                component: AdminBikeUpdateComponent
            },
            {
                path: 'lockers/all',
                component: AdminLockerAllComponent
            },
            {
                path: 'lockers/create',
                component: AdminLockerCreate
            },
            {
                path: 'lockers/edit/:id',
                component: AdminLockerEditComponent
            },
            {
                path: 'pass-types',
                component: PassTypesComponent
            },
            {
                path: 'passtype/edit/:id',
                component: PassTypeEditComponent
            },
            {
                path: 'home',
                component: AdminHomeComponent
            }
        ]
    }
]

@NgModule({
    declarations: [
    ],
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class AdminRoutingModule {}