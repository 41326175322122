import { ProfileManager } from './profile-manager';
import { Model } from './model';

export class Profile extends Model
{
    private static requiredFields = [
        'id',
        'username'
    ]
    private static optionalFields = [
        'idUserManager'
    ]
    
    public id : number
    public username : string
    public idUserManager : number
    public profileManager : ProfileManager
    
    public static loadFromJSONObject(json) : Profile
    {
        var profile = new Profile()
        this.load<Profile>(json, profile, this.requiredFields, this.optionalFields)
        
        if (json.hasOwnProperty('profilemanager'))
        {
            profile.profileManager = ProfileManager.loadFromJSONObject(json['profilemanager'])
        }
        
        return profile
    }
}