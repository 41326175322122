import { ApiService } from 'src/app/services/api.service';
import { Locker } from 'src/app/models/locker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bike } from './../../../models/bike';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-bike-associate',
    templateUrl: './bike-associate.html'
})
export class BikeAssociateComponent implements OnInit
{
    bikeId = -1
    lockerId = -1
    bike : Bike
    loaded = false
    form : FormGroup
    lockers : Locker[] = []
    submitted = false
    loading = false
    
    constructor(
        private route : ActivatedRoute,
        private data : DataService,
        private formBuilder : FormBuilder,
        private api : ApiService,
        private router : Router
    )
    {
        
    }
    
    get f() { return this.form.controls }
    
    ngOnInit()
    {
        this.form = this.formBuilder.group({
            locker: ['', Validators.required],
            lat: ['', Validators.required],
            lon: ['', Validators.required]
        })
        this.route.params.subscribe(params => {
            this.bikeId = +params['id']
            
            this.data.getBikeByID(this.bikeId, (error, bike) => {
                this.bike = bike
                
                this.data.loadLockersManager().then(() => {
                    this.lockers = this.data.lockerData
                    this.loaded = true
                })
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.form.invalid)
        {
            return
        }
        this.loading = true
        
        console.log(this.f.locker)
        let lat = this.f.lat.value
        let lon = this.f.lon.value
        
        this.api.associateBikeLocker(this.bike, this.f.locker.value, lat, lon).then(() => {
            this.loading = false
            this.submitted = false
            
            this.router.navigate(['/dashboard/bikes/edit/'+this.bikeId.toString()])
        }).catch(err => {
            this.loading = false
            this.submitted = false
        })
    }
}