import { ClientEditComponent } from './pages/client-edit/client-edit';
import { BikeAssociateComponent } from './pages/bike-associate/bike-associate';
import { LockerAllComponent } from './pages/locker-all/locker-all';
import { ClientCreateComponent } from './pages/client-create/client-create';
import { ClientAllComponent } from './pages/client-all/client-all';
import { BikeUpdateComponent } from './pages/bike-update/bike-update.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ManagerRoutingModule } from './manager.routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ManagerDashboardComponent } from './pages/manager-dashboard/manager-dashboard.component';
import { RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BikeCreateComponent } from './pages/bike-create/bike-create.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BikeAllComponent } from './pages/bike-all/bike-all.component';
import { LockerEditComponent } from './pages/locker-edit/locker-edit';
import { NgSelectModule } from '@ng-select/ng-select';
import { PassAssignComponent } from './pages/pass-assign/pass-assign';
import { ManagerHomeComponent } from './pages/home/home';
import { LockerCreate } from './pages/locker-create/locker-create';
import { ActivityAllComponent } from './pages/activity-all/activity-all';

@NgModule({
    declarations: [
        ManagerDashboardComponent,
        ManagerHomeComponent,
        BikeAllComponent,
        BikeCreateComponent,
        BikeUpdateComponent,
        BikeAssociateComponent,
        ClientAllComponent,
        ClientCreateComponent,
        ClientEditComponent,
        LockerAllComponent,
        LockerEditComponent,
        LockerCreate,
        PassAssignComponent,
        ActivityAllComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    imports: [
        CommonModule,
        FormsModule,
        ManagerRoutingModule,
        NgSelectModule,
        TranslateModule.forChild(),
        NgbModule,
        RouterModule,
        ReactiveFormsModule
    ],
    providers: [
        TranslateService
    ]
})
export class ManagerModule {}