import { Manager } from './../../../models/manager';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-manager-edit',
    templateUrl: './manager-edit.html'
})
export class ManagerEditComponent implements OnInit
{
    id = -1
    
    loaded = false
    updateForm : FormGroup
    loading = false
    submitted = false
    manager : Manager
    
    constructor(
        private formBuilder : FormBuilder,
        private route : ActivatedRoute,
        private api : ApiService,
        private data : DataService
    ){}
    
    get f() { return this.updateForm.controls }
    
    ngOnInit()
    {
        this.updateForm = this.formBuilder.group({
            businessName: ['', Validators.required],
            name: [''],
            surname: [''],
            gender: [''],
            phone: ['']
        })
        
        this.route.params.subscribe(params => {
            this.id = +params['id']
            
            this.data.getManagerById(this.id, (error, manager) => {
                if (error)
                {
                    return
                }
                
                this.manager = manager
                this.f.name.setValue(manager.profile.name)
                this.f.surname.setValue(manager.profile.surname)
                if (manager.profile.gender != undefined)
                {
                    this.f.gender.setValue(Number.parseInt(manager.profile.gender))
                }
                this.f.businessName.setValue(manager.profile.ragioneSociale)
                this.f.phone.setValue(manager.profile.phoneNumber)
                
                this.loaded = true
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = false
        
        if (this.updateForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.manager.profile.name = this.f.name.value
        this.manager.profile.surname = this.f.surname.value
        this.manager.profile.phoneNumber = this.f.phone.value
        this.manager.profile.ragioneSociale = this.f.businessName.value
        if (this.f.gender.value != 0)
        {
            this.manager.profile.gender = this.f.gender.value
        }
        
        this.api.updateManagerProfile(this.manager).then(result => {
            this.loading = false
            this.submitted = false
        }).catch(err => {
            this.loading = false
            this.submitted = false
            console.error(err)
        })
    }
}