import { Model } from './model';

export class PassType extends Model
{
	public static requiredFields = [
		'id',
		'duration',
		'desc',
        'price'
	]
	public static optionalFields = [
        'visible'
	]
    
	public id : number
	public duration : number
	public desc : string
	public price : number
	public visible : number
    
	public static loadFromJSONObject(json)
	{
		var type = new PassType()
        
		this.load(json, type, this.requiredFields, this.optionalFields)
        
		return type
	}
    
	public static loadFromJSONArray(json : [])
	{
		var types : PassType[] = []
        
		for (var i = 0; i < json.length; i++)
		{
			var type = this.loadFromJSONObject(json[i])
			types.push(type)
		}
        
		return types
	}
}