import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-locker-create',
    templateUrl: './locker-create.html'
})
export class AdminLockerCreate implements OnInit
{
    loading = false
    submitted = false
    createForm : FormGroup
    
    constructor(
        private formBuilder : FormBuilder,
        private api : ApiService,
        private router : Router
    ) {}
    
    get f() { return this.createForm.controls }
    
    ngOnInit()
    {
        this.createForm = this.formBuilder.group({
            udid: ['', Validators.required],
            desc: ['']
        })
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.createForm.invalid)
        {
            return
        }
        
        this.loading = true
        
        this.api.createLocker(
            this.f.udid.value,
            this.f.desc.value
        ).then(result => 
        {
            console.log(result)
            
            this.loading = false
            this.submitted = false
            
            this.router.navigate(['/admin/dashboard/lockers/edit/'+result['data']['locker']['id']])
        }).catch(err => 
        {
            this.loading = false
            this.submitted = false
        })
    }
}