import { OnInit, Component } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.html'
})
export class AdminDashboardComponent implements OnInit
{
    managerCollapse = true
    bikeCollapse = true
    lockerCollapse = true
    
    constructor(
        private auth : AuthService
    )
    {
        
    }
    
    ngOnInit()
    {
        
    }
    
    logoutClick()
    {
        this.auth.logout(['/login'])
    }
}