import { Manager } from 'src/app/models/manager';
import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-manager-home',
    templateUrl: './home.html'
})
export class AdminHomeComponent implements OnInit
{
    userText = ""
    bikeText = ""
    lockerText = ""
    activityText = ""
    clients : Manager[] = []
    
    constructor(
        private api : ApiService,
        private data : DataService
    ) {}
    
    ngOnInit()
    {
        this.data.loadManagersListAll().then(() => {
            this.userText = this.data.managerData.length.toString()
            this.clients = this.data.managerData.splice(this.data.managerData.length - 3, 3)
        })
        this.data.loadBikesAll().then(() => {
            this.bikeText = this.data.bikeData.length.toString()
        })
        this.data.loadLockersAll().then(() => {
            this.lockerText = this.data.lockerData.length.toString()
        })
        
        this.data.loadActivitiesAll().then(() => {
            this.activityText = this.data.activityData.length.toString()
        })
    }
}