import { OnInit, Component } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Manager } from 'src/app/models/manager';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'app-managers-all',
    templateUrl: './managers-all.html'
})
export class ManagersAllComponent implements OnInit
{    
    loaded = false
    managers : Manager[] = []
    
    constructor(
        private api : ApiService,
        private modalService : NgbModal,
        private router : Router
    )
    {
        
    }
    
    ngOnInit()
    {
        this.api.getManagersAll().then(result => 
        {
            if (result == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
                this.managers = Manager.loadFromJSONArray(result['user'])
                this.loaded = true
			} else
			{
				throw new Error('Could not load manager data, result: '+result)
			}
        }).catch(err => 
        {
            
        })
    }
    
    rowClick(i : number)
    {
        this.router.navigate(['/admin/dashboard/manager/edit/'+i.toString()])
    }
    
    deleteManager(i : number, modalContent)
    {
        if (i < 0 || i >= this.managers.length)
        {
            return
        }
        this.modalService.open(modalContent).result.then(result => 
        {
            if (result === 'yes')
            {
                var manager = this.managers[i]
                this.api.deleteManager(manager.id).then((result) => 
                {
                    if (result == undefined)
                    {
                        return
                    }
                    this.managers.splice(i, 1)
                }).catch(err => 
                {
                    
                })
            }
        }, reject => {
            console.log(reject)
        })
    }
}