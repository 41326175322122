import { ApiService } from './../../../services/api.service';
import { AuthService } from './../../../auth/services/auth.service';
import { ClientManager } from './../../../models/client-manager';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-client-create',
	templateUrl: './client-create.html'
})
export class ClientCreateComponent implements OnInit, AfterViewInit
{
	@ViewChild('usernameInput') usernameInput : ElementRef
	
	public createForm : FormGroup
	public submitted = false
	public loading = false
	
	public showUserOccupied = false
	public userOccupied = false
	
	constructor(
		private translate : TranslateService,
		private formBuilder : FormBuilder,
		private auth : AuthService,
		private api : ApiService,
		private router : Router
	)
	{
		
	}
	
	get f() { return this.createForm.controls }
	
	ngOnInit()
	{
		this.createForm = this.formBuilder.group({
			name: ['', Validators.required],
			surname: ['', Validators.required],
			phone: ['', Validators.required],
			username: ['', Validators.required],
			password: ['', Validators.required],
			note: [''],
			gender: ['']
		})
		this.f.gender.setValue('1')
	}
	
	ngAfterViewInit()
	{
		const input = fromEvent(this.usernameInput.nativeElement, 'input')
		let types = input.pipe(map((event : Event) => {}))
		types = types.pipe(debounceTime(300))
		types.subscribe(() => {
			this.checkUsernameExists()
		})
	}
	
	checkUsernameExists()
	{
		const username = this.f.username.value
		if (!username || username == '')
		{
			return
		}
		
		this.api.checkUserExists(username).then(res => {
			if (res['result'] == 404)
			{
				this.showUserOccupied = true
				this.userOccupied = false
			} else if (res['result'] == 200)
			{
				this.showUserOccupied = true
				this.userOccupied = true
			}
		})
	}
	
	onSubmit()
	{
		this.submitted = true
		if (this.userOccupied)
		{
			console.log('user occupied')
			return
		}
		
		if (this.createForm.invalid)
		{
			return
		}
		this.loading = true
		
		var client = new ClientManager()
		client.profile.name = this.f.name.value
		client.profile.surname = this.f.surname.value
		client.username = this.f.username.value
		client.password = this.f.password.value
		client.idUserManager = this.auth.getProfileID()
		client.profile.phoneNumber = this.f.phone.value
		client.profile.gender = this.f.gender.value.toString()
		client.profile.note = this.f.note.value
		
		this.api.registerClientManager(client).then(result => 
		{
			if (result == undefined)
			{
				return
			}
			
			if (result['result'] == undefined)
			{
				return
			}
			
			if (result['result'] == 200)
			{
				console.log('Client manager created')
				
				this.loading = false
				this.submitted = false
				
				console.log(result)
				this.router.navigate(['/dashboard/clients/edit/'+result['data'].user.id])
			} else
			{
				throw 'Failed to register client: '+result
			}
		}).catch(err => 
		{
			console.error(err)
			this.loading = false
			this.submitted = false
		})
	}
}