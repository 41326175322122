import { ConfigService } from './../../../services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.html'
})
export class LoginComponent implements OnInit
{
    loginForm : FormGroup
    loading = false
    submitted = false
    error = 0
    
    constructor(
        private formBuilder : FormBuilder,
        public config : ConfigService,
        private auth : AuthService,
        private router : Router
    )
    {}
    
    get f() { return this.loginForm.controls }
    
    ngOnInit()
    {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        })
        
        
    }
    
    onSubmit()
    {
        this.submitted = true
        
        if (this.loginForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.auth.loginShared(
            this.f.username.value,
            this.f.password.value
        , (error, isAdmin) => {
            this.loading = false
                this.submitted = false
                this.error = error
                
                if (error)
                {
                    return
                }
                
                if (isAdmin)
                {
                    this.router.navigate(['/admin/dashboard/home'])
                } else
                {
                    this.router.navigate(['/dashboard/home'])
                }
        })
    }
}