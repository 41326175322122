import { ClientManager } from './../../../models/client-manager';
import { ApiService } from 'src/app/services/api.service';
import { OnInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-client-all',
    templateUrl: './client-all.html',
    styleUrls: ['./client-all.scss']
})
export class ClientAllComponent implements OnInit
{
    public clients : ClientManager[]
    
    constructor(
        private data : DataService,
        private modalService : NgbModal,
        private api : ApiService,
        private router : Router
    )
    {
        
    }
    
    ngOnInit() 
    {
        this.data.loadClientsAll().then(() => {
            this.clients = this.data.clientData
        })
    }
    
    rowClick(i : number)
    {
        this.router.navigate(['/dashboard/clients/edit/'+i.toString()])
    }
    
    deleteClick(i : number, modalContent)
    {
        if (i < 0 || i >= this.clients.length)
        {
            return
        }
        this.modalService.open(modalContent).result.then(result => 
        {
            if (result === 'yes')
            {
                var client = this.clients[i]
                this.api.deleteClientManager(client.id).then((result) => 
                {
                    if (result == undefined)
                    {
                        return
                    }
                    this.clients.splice(i, 1)
                }).catch(err => 
                {
                    
                })
            }
        }, reject => {
            console.log(reject)
        })
    }
}