import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Morebike';

	constructor(
        private translate : TranslateService,
        private route : ActivatedRoute
	)
	{
		translate.setDefaultLang('en')
        translate.use('en')
        
    }
    
    ngOnInit()
    {
        
    }
}
