import { TranslateModule } from '@ngx-translate/core';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { AuthRoutingModule } from './auth.routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagerLoginComponent } from './pages/manager-login/manager-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientLoginComponent } from './pages/client-login/client-login';
import { AdminLoginComponent } from './pages/admin-login/admin-login';
import { LoginComponent } from './pages/login/login';

@NgModule({
	declarations: [
        AuthPageComponent,
        LoginComponent,
        AdminLoginComponent,
        ManagerLoginComponent,
        ClientLoginComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
	imports: [
        CommonModule,
        NgbModule,
        AuthRoutingModule,
        TranslateModule.forChild(),
        ReactiveFormsModule
	]
})
export class AuthModule { }
