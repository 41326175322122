import { ProfileClientManager } from './profile-client-manager';
import { Model } from './model';

export class ClientManager extends Model
{
    private static requiredFields = [
        'id',
        'username',
        'active',
        'idUserManager'
    ]
    private static optionalFields = [
        
    ]
    
    public id : number
    public active : number
    public username : string
    public password : string
    public idUserManager : number
    public profile : ProfileClientManager
    
    constructor()
    {
        super();
        this.profile = new ProfileClientManager()
    }
    
    public static loadFromJSONObject(json): ClientManager
    {
        var client = new ClientManager()
        this.load(json, client, this.requiredFields, this.optionalFields)
        
        client.profile = ProfileClientManager.loadFromJSONObject(json.profileclientmanager)
        
        return client
    }
    
    public static loadFromJSONArray(json : []): ClientManager[]
    {
        var clients : ClientManager[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var client = this.loadFromJSONObject(json[i])
            clients.push(client)
        }
        
        return clients
    }
}