import { Manager } from './../../../models/manager';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit, Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-manager-register',
	templateUrl: './manager-register.html'
})
export class ManagerRegisterComponent implements OnInit, AfterViewInit
{
	@ViewChild('usernameInput') usernameInput : ElementRef
	
	public registerForm : FormGroup
	public loading = false
	public submitted = false
	
	public showUserOccupied = false
	public userOccupied = false
	
	constructor(
		private formBuilder : FormBuilder,
		private translate : TranslateService,
		private api : ApiService,
		private router : Router
	)
	{}
	
	get f() { return this.registerForm.controls }
	
	ngOnInit()
	{
		this.registerForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			name: ['', Validators.required],
			surname: ['', Validators.required],
			phone: ['', Validators.required],
			businessName: ['', Validators.required],
			gender: ['', Validators.required]
		})
		this.f.gender.setValue(1)
	}
	
	ngAfterViewInit()
	{
		const input = fromEvent(this.usernameInput.nativeElement, 'input')
		let types = input.pipe(map((event : Event) => {}))
		types = types.pipe(debounceTime(300))
		types.subscribe(() => {
			this.checkUsernameExists()
		})
	}
	
	checkUsernameExists()
	{
		const username = this.f.username.value
		if (!username || username == '')
		{
			return
		}
		
		this.api.checkUserExists(username).then(res => {
			if (res['result'] == 404)
			{
				this.showUserOccupied = true
				this.userOccupied = false
			} else if (res['result'] == 200)
			{
				this.showUserOccupied = true
				this.userOccupied = true
			}
		})
	}
	
	onSubmit()
	{
		console.log('submit click')
		if (this.userOccupied)
		{
			console.log('user occupied')
			return
		}
		
		this.submitted = true
		if (this.registerForm.invalid)
		{
			return
		}
		this.loading = true
		
		var manager = new Manager()
		manager.username = this.f.username.value
		manager.password = this.f.password.value
		manager.profile.name = this.f.name.value
		manager.profile.surname = this.f.surname.value
		manager.profile.phoneNumber = this.f.phone.value
		manager.profile.ragioneSociale = this.f.businessName.value
		manager.profile.gender = this.f.gender.value.toString()
		
		this.api.registerManager(manager).then(result => {
			console.log('Api result: ' +result)
			if (result == undefined)
			{
				throw 'Error rgistering manager'
			}
			if (result['result'] == 200)
			{
				var userid = result['data'].user.id
					
				// TODO navigate to edit manager
				this.router.navigate(['/admin/dashboard/managers/all'])
			} else
			{
				throw 'Error registering manager: '+result
			}
		}).catch(err => 
		{
			this.loading = false
			this.submitted = false
			console.log('catch')
		})
	}
}