import { Model } from './model';
import { BikeLocker } from './bike-locker';
import { ClientManager } from './client-manager';

export class Activity extends Model
{
    public static requiredFields = [
        'id',
        'idUser'
    ]
    public static optionalFields = []
    
    public id : number
    public idUser : number
    public date : Date
    public latStart : number
    public lngStart : number
    public latEnd : number
    public lngEnd : number
    public endDate : Date
    public value : number
    public bikeLocker : number
    public clientId : number
    public clientName : string
    
    public getStartDateString()
    {
        var str = ""
        
        str = this.date.getDate()+"/"+(this.date.getMonth()+1)+"/"+this.date.getFullYear()
        
        return str
    }
    public getEndDateString()
    {
        var str = ""
        
        if (this.endDate != undefined && this.endDate != null)
        {
            str = this.endDate.getDate()+"/"+(this.endDate.getMonth()+1)+"/"+this.endDate.getFullYear()
        } else
        {
            str = '-'
        }
        
        return str
    }
    public getUseString()
    {
        var str = ""
        
        if (this.endDate != undefined && this.endDate != null)
        {
            str = (Math.abs(this.date.getTime() - this.endDate.getTime()) / 36e5).toString();
        } else
        {
            str = '0'
        }
        
        return str + ' h'
    }
    
    public static loadFromJSONObject(json)
    {
        var activity : Activity = new Activity()
        
        this.load(json, activity, this.requiredFields, this.optionalFields)
        
        activity.clientId = json['user']['id']
        activity.clientName = json['user']['username']
        activity.bikeLocker = json['bikelocker']['id']
        
        activity.date = new Date(json['createdAt'])
        if (json['endDate'] != null)
        {
            activity.endDate = new Date(json['endDate'])
        }
        
        return activity
    }
    public static loadFromJSONArray(json : [])
    {
        var arr : Activity[] = []
        
        for (var i = 0; i < json.length; i++)
        {
            var a = this.loadFromJSONObject(json[i])
            arr.push(a)
        }
        
        return arr
    }
}