import { ConfigService } from './../../../services/config.service';
import { DataService } from 'src/app/services/data.service';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ClientManager } from 'src/app/models/client-manager';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-users-all',
    templateUrl: './users-all.html'
})
export class UsersAllComponent implements OnInit
{
    id = -1
    loaded = false
    public clients : ClientManager[] = []
    
    constructor(
        private config : ConfigService,
        private data : DataService,
        private route : ActivatedRoute,
        private api : ApiService,
        private modalService : NgbModal
    ) {}
    
    ngOnInit()
    {
        this.route.params.subscribe(params => {
            this.id = +params['id']
            
            this.api.listClientsAdmin(this.id).then(result => {
                if (result['result'] == 200)
                {
                    var clients = ClientManager.loadFromJSONArray(result['user'])
                    this.clients = clients
                }
            }).catch(err => {
                
            })
        })
    }
    
    deleteClick(i : number, modalContent)
    {
        if (i < 0 || i >= this.clients.length)
        {
            return
        }
        this.modalService.open(modalContent).result.then(result => 
        {
            if (result === 'yes')
            {
                var client = this.clients[i]
                this.api.deleteClient(client.id).then((result) => 
                {
                    if (result == undefined)
                    {
                        return
                    }
                    this.clients.splice(i, 1)
                }).catch(err => 
                {
                    
                })
            }
        }, reject => {
            console.log(reject)
        })
    }
}