import { ApiService } from './../../../services/api.service';
import { Locker } from './../../../models/locker';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-locker-edit',
    templateUrl: './locker-edit.html'
})
export class AdminLockerEditComponent implements OnInit
{
    public updateForm : FormGroup
    public loading = false
    public submitted = false
    public id = -1
    public locker : Locker
    public loaded = false
    
    constructor(
        private route : ActivatedRoute,
        private formBuilder : FormBuilder,
        private data : DataService,
        private api : ApiService
        
    )
    {
        
    }
    
    get f() { return this.updateForm.controls }
    
    ngOnInit()
    {
        this.updateForm = this.formBuilder.group({
            description: ['']
        })
        
        this.route.params.subscribe(params => {
            this.id = params.id
            
            this.data.getLockerByID(this.id, (error, locker) => {
                if (error)
                {
                    return
                }
                this.locker = locker
                this.loaded = true
                this.f.description.setValue(locker.desc)
            })
        })
    }
    
    onSubmit()
    {
        this.submitted = true
        if (this.updateForm.invalid)
        {
            return
        }
        this.loading = true
        
        this.locker.desc = this.f.description.value
        
        this.api.updateLocker(this.locker).then(result => {
            this.loading = false
            this.submitted = false
        }).catch(err => {
            console.error(err)
            this.loading = false
            this.submitted = false
        })
    }
}